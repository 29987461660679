import { Link, PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';
import CommonBanner from '../components/banner/CommonBanner';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import libphonenumber from 'google-libphonenumber';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { mockMenuDatas } from '../elements/baseMock';
import { Button, Modal } from 'react-bootstrap';
import { SuccessSvg } from '../../static/svg/invoiceSvgCom';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

declare let grecaptcha: any;
declare let gtag: any;
declare let window: any;
declare module 'yup' {
  interface StringSchema {
    phone(value: string): this;
    companyPhone(value: string): this;
  }
}
interface IFormInputs {
  first_name?: string;
  last_name?: string;
  email?: string;
  mobile_number?: string;
  question?: string;
}

const Education = ({ location }: PageProps) => {
  useEffect(() => {
    console.log(location.hash);
    setTimeout(() => {
      window.location.hash = '';
      window.location.hash = location.hash;
    });
  }, [location.hash]);

  const [phone, setPhone] = useState<intlTelInput.Plugin>();
  Yup.addMethod(Yup.string, 'phone', function (message) {
    return this.test({
      name: 'phone',
      exclusive: false,
      message: message || 'must be a phone number',
      test: async function (value) {
        if (!phone || !value) {
          return false;
        }
        try {
          const area = phone.getSelectedCountryData().iso2.toUpperCase();
          const number = phoneUtil.parse(value, area);
          return phoneUtil.isValidNumber(number);
        } catch (error) {}
        return false;
      },
    });
  });
  useEffect(() => {
    const mobileInput = document.querySelector('#mobile');
    if (mobileInput) {
      const phoneState = intlTelInput(mobileInput, {
        initialCountry: 'auto',
        geoIpLookup: (callback) => {
          axios
            .get<
              undefined,
              {
                data: {
                  country_code: string;
                };
              }
            >('https://ipapi.co/json/', {
              responseType: 'json',
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.country_code) {
                callback(resp.data.country_code.toLowerCase());
              } else callback('sg');
            });
        },
        preferredCountries: ['sg', 'cn', 'hk', 'us'],
      });
      setPhone(phoneState);
    }
  }, []);

  const schema = Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    mobile_number: Yup.string().required('Required').phone('The phone number is invalid.'),
    question: Yup.string().required('Required'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });
  const [contactObj, setContactObj] = useState<Record<string, any>>({});
  const [show, setShow] = useState(false);
  const onSubmit = (data: IFormInputs) => {
    if (!phone) {
      return;
    }
    // 验证
    const area = phone.getSelectedCountryData().dialCode;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute('6Le4KpEaAAAAACJw4liW38ANFo2whC6FjqdWhDdX', {
          action: 'submit',
        });
        const result = await axios.post(
          `${process.env.API_URL}/api/schedule-chats`,
          {
            ...data,
            token,
            mobile: `${area}${data.mobile_number}`,
            query_type: 'Partnership opportunities',
            full_name: `${data.first_name}${data.last_name}`,
          },
          {
            responseType: 'json',
          },
        );

        if (result && result.data && result.data.status) {
          const contactObj = {
            email: data?.email,
            phone: `${area}${data.mobile_number}`,
          };
          setContactObj(contactObj);
          setShow(true);
          gtag('set', 'user_data', contactObj);
          gtag('event', 'conversion', {
            send_to: 'AW-10939055673/K2UPCPXIroAYELn8kuAo',
          });
        }
      } catch (error) {}

      // 失败弹窗
    });
  };

  const rendorCardItem = (item: any, key: number) => {
    return (
      <div className="card col-4" key={key}>
        <div>
          <p className="-title">{item?.title}</p>
          <ul>
            {item?.data?.map((subItem: any, subKey: number) => {
              return subItem?.description ? (
                <li>
                  <a href={`/banco-education#${subItem?.value}`}>{subItem?.label}</a>
                  <p className="li-des">
                    {subItem?.highlight && <span className="-highlight">{subItem?.highlight}</span>}
                    {subItem?.description}
                  </p>
                </li>
              ) : (
                <li key={subKey}>
                  <a href={`/banco-education#${subItem?.value}`}>{subItem?.label}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <a className="view-link" href={`/banco-education#${item?.link}`}>{`View > >`}</a>
      </div>
    );
  };

  return (
    <Layout>
      <Seo
        title="About banco| Financing platform for growing businesses in Asia"
        description="banco is a financing platform for growing businesses in Asia. Unlock opportunities in supply chain to empower companies control cash flow and scale business"
      />
      <main className="main-wrapper">
        {/* Education */}
        <CommonBanner>
          <HeaderCommon />

          <div className="education-wrap">
            <div className="row content-1">
              <div className="-left col">
                <p className="-title">Welcome to banco Partner Onboarding</p>
                <p className="-sub-title">Your first step to achieving success with us</p>
                <p className="-description">
                  We provide the necessary training, so no specialist knowledge is necessary to
                  begin as a banco Partner (BP). You can choose to work on a part-time or full-time
                  basis, but it’s worth noting that our higher-earning banco Partners (BP) are
                  usually committed professionals who are keen to succeed and are willing to
                  allocate their resources to achieving that success.
                </p>
              </div>
              <div className="col">
                <img
                  className="light-mode"
                  src="/images/education/home-1.png"
                  //   style={{ width: 340 }}
                />
                <img
                  className="light-mode"
                  src="/images/education/home-2.png"
                  //   style={{ width: 340 }}
                />
              </div>
            </div>

            <div className="row content-2">
              {rendorCardItem(mockMenuDatas?.[0], 0)}
              {rendorCardItem(mockMenuDatas?.[1], 1)}
            </div>

            <div className="row content-2">
              {rendorCardItem(mockMenuDatas?.[2], 2)}
              {rendorCardItem(mockMenuDatas?.[3], 3)}
            </div>
          </div>
        </CommonBanner>

        <div className="contact-us-wrap">
          <p>
            For any further questions not covered in the modules above please contact us directly
            via the form below
          </p>
          <button
            type="button"
            className="banco-btn btn-ghost"
            data-bs-toggle="modal"
            data-bs-target="#contactUsForm"
          >
            Contact us
          </button>
        </div>
        <div
          className="modal fade contact-us-modal"
          id="contactUsForm"
          tabIndex={-1}
          aria-labelledby="eligibilityFormModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered  ">
            <div className="modal-content">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-header">
                  <h5>Contact us</h5>
                  <img src="/images/education/contact.png" alt="" />
                  <button
                    type="button"
                    // className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src="/images/close-img.png" alt="" />
                  </button>
                </div>
                <div className="modal-body">
                  <div className="full-name-wrap">
                    <div>
                      <label htmlFor="firstname">Full name</label>
                      <input {...register('first_name')} id="firstname" placeholder="First Name" />
                      <p>{errors.first_name?.message}</p>
                    </div>
                    <div>
                      <label htmlFor="lastname">
                        <span style={{ opacity: 0 }}>Last name</span>
                      </label>
                      <input {...register('last_name')} id="lastname" placeholder="Last name" />
                      <p>{errors.last_name?.message}</p>
                    </div>
                  </div>

                  <label htmlFor="email">Email</label>
                  <input
                    {...register('email')}
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                  />
                  <p>{errors.email?.message}</p>

                  <label htmlFor="mobile">Contact Number</label>
                  <input
                    {...register('mobile_number')}
                    id="mobile"
                    name="mobile_number"
                    placeholder="Contact Number"
                  />
                  <p>{errors.mobile_number?.message}</p>

                  <label htmlFor="question">Comment/Question</label>
                  <input
                    {...register('question')}
                    id="question"
                    name="question"
                    placeholder="Enter comment/question"
                  />
                  <p>{errors.question?.message}</p>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal className="contact-modal" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <button onClick={() => setShow(false)}>
              <img src="/images/close-img.png" alt="" />
            </button>
          </Modal.Header>
          <Modal.Body>
            {SuccessSvg({})}
            <p>Thank you for reaching out to banco.</p>
            <p>
              {contactObj.phone && contactObj.email && (
                <>
                  Your submitted email address is <strong>{contactObj.email}</strong>, and your
                  mobile number is <strong>{contactObj.phone}</strong>.
                </>
              )}
            </p>
            <p>We will get back to you as soon as we can.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShow(false)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <Footer />
      </main>
    </Layout>
  );
};

export default Education;
